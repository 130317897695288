import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Footer } from "components/Footer";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { convertToQueryString } from "@sprint1/pkg/src/url/convertToQueryString";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { S1InputField } from "@sprint1/pkg/src/form/input/Field";
import { useSearchTenants } from "api/client/tenant/searchTenants";
import { createFilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest.helper";
import { ReSelectField } from "@sprint1/pkg/src/reSelect/Field";

export function SavingsReportModal(props: UseSavingsReportModalProp) {
  const { translate } = useTranslation();

  if (!props.show) {
    return null;
  }

  return (
    <Modal show={true} centered>
      <Modal.Header>
        <Modal.Title>{translate("__savingsReport")}</Modal.Title>
        <div>{}</div>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <Field name="startDate">
            <Label>{translate("__startDate")}</Label>
            <S1InputField
              type="date"
              value={props.startDate}
              onChange={(e) => {
                props.setStartDate(e.target.valueAsDate);
              }}
            />
          </Field>
          <Field name="endDate">
            <Label>{translate("__endDate")}</Label>
            <S1InputField
              type="date"
              value={props.endDate}
              onChange={(e) => {
                props.setEndDate(e.target.valueAsDate);
              }}
            />
          </Field>

          {props.tenantsApi.tenants && (
            <Field name="client" isRequired>
              <Label>{translate("__client")}</Label>
              <ReSelectField
                value={props.selectedClient}
                options={props.tenantsApi.tenants.results.map((t) => {
                  return { label: t.name, value: t.id };
                })}
                isClearable
                onChange={({ selectedValue }) => {
                  props.setSelectedClient(selectedValue);
                }}
              />
            </Field>
          )}
          {props.startDate && props.selectedClient && (
            <div>
              <a
                className="d-block my-2"
                href={`/api/reporting/Encounters${getQueryString(props)}`}
                target="_blank"
                rel="noreferrer"
              >
                {translate("__downloadEncountersReport")}
              </a>
              <a href={`/api/reporting/MessageCount${getQueryString(props)}`} target="_blank" rel="noreferrer">
                {translate("__downloadMessageCountReport")}
              </a>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100">
          <Footer
            variant="close"
            closeButtonProps={{
              text: translate("__close"),
            }}
            onCloseClick={async () => {
              props.setShow(false);
            }}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function getQueryString(props: UseSavingsReportModalProp) {
  const qs = convertToQueryString({
    startDate: props.startDate?.toISOString(),
    endDate: props.endDate?.toISOString(),
    tenantId: props.selectedClient,
  });
  return qs;
}
export function useSavingsReportModal() {
  const [show, setShow] = useState(false);
  const [selectedClient, setSelectedClient] = useState<string>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>();
  const tenantsApi = useSearchTenants({
    runOnMount: true,
    request: createFilterAndSortRequest(),
  });

  function showModal() {
    setShow(true);
  }

  return {
    selectedClient,
    setSelectedClient,
    showModal,
    show,
    setShow,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    tenantsApi,
  };
}

export type UseSavingsReportModalProp = ReturnType<typeof useSavingsReportModal>;
