import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { getQueryStringValue } from "@sprint1/pkg/src/url/getQueryStringValue";
import { Formik, Form } from "formik";

import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { Footer } from "components/Footer";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { MdOrthoUser } from "api/types/mdOrthoUser";
import { useState } from "react";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { createUser } from "api/client/user/createUser";
import { searchUsers } from "api/client/user/searchUsers";
import { updateUser } from "api/client/user/updateUser";

import { useAppRoutes } from "routes/useRoutes";
import { Fieldset } from "@sprint1/pkg/src/form/fieldset";
import { Legend } from "@sprint1/pkg/src/form/legend/Legend";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { ReSelectFormikField } from "@sprint1/pkg/src/reSelect/FormikField";
import { useGetConfiguration } from "@sprint1/pkg/src/api/auth/client/configuration/getConfiguration";
import { roles, RolesEnum } from "common/roles";

import { useFormikContext } from "formik";
import { Breadcrumb } from "components/Breadcrumb";
import { userFilters } from "api/helpers/searchUsers.helper";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";
import { useAppUser } from "common/useAppUser";
import { AccessLevel } from "api/types/accessLevel";

import { EnumHelper } from "@sprint1/pkg/src/utils/EnumHelper";
import { getBreadCrumbs } from "./helpers";

const name = nameof<MdOrthoUser>;

export function EditUser() {
  const { translate } = useTranslation();
  const data = useData();
  const title = !data.userId ? translate("__addUser") : translate("__editUser");

  return (
    <PageLayout breadcrumb={<Breadcrumb crumbs={getBreadCrumbs()} current={translate("__editUser")} />}>
      <PageTitleSimple title={title} left={title} />
      {!data.user || !data.configuration ? (
        <ViewPortLoading />
      ) : (
        <Formik initialValues={data.user} onSubmit={(userInForm) => data.save(userInForm)}>
          <Form noValidate>
            <div className="row">
              <div className="col-md-8 col-lg-6">
                <AdminFields data={data} />
                <Fields />

                <Footer
                  variant="backAndSave"
                  saveButtonProps={{
                    showSuccess: data.success,
                    showSpinner: data.saving,
                    disabled: data.saving,
                  }}
                />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </PageLayout>
  );
}

function AdminFields({ data }: { data: UseDataReturnType }) {
  const appUser = useAppUser();
  const { translate } = useTranslation();

  if (!(appUser.isAdmin || appUser.isClientAdmin)) {
    return null;
  }
  return (
    <>
      <Field name={name("roles")} isRequired>
        <Label>{translate("__roles")}</Label>
        <ReSelectFormikField
          multiple
          options={data.configuration!.availableRoles.map((o) => {
            return { label: translate(`__role-${o}`), value: o };
          })}
          autoFocus={appUser.isAdmin}
        />
      </Field>

      {!data.newUser && (
        <Fieldset>
          <Legend>{translate("__active?")}</Legend>
          <Field name={name("active")} isCheckOrRadio isInline>
            <S1InputFormikField type="radio" value={true} parse="asBoolean" />
            <Label isCheckOrRadio>{translate("__yes")}</Label>
          </Field>

          <Field name={name("active")} isCheckOrRadio isInline>
            <S1InputFormikField type="radio" value={false} parse="asBoolean" />
            <Label isCheckOrRadio>{translate("__no")}</Label>
          </Field>
        </Fieldset>
      )}
    </>
  );
}

function Fields() {
  const { translate } = useTranslation();
  const formikContext = useFormikContext<MdOrthoUser>();
  const isDoctor = roles.hasRole(RolesEnum.Doctor, formikContext.values.roles);
  const isPatient = roles.hasRole(RolesEnum.Patient, formikContext.values.roles);
  const appUser = useAppUser();

  return (
    <>
      <Field name={name("firstName")} isRequired>
        <Label>{translate("__firstName")}</Label>
        <S1InputFormikField type="text" autoFocus={!appUser.isAdmin} />
      </Field>

      {isDoctor && (
        <Field name={name("middleName")}>
          <Label>{translate("__middleName")}</Label>
          <S1InputFormikField type="text" />
        </Field>
      )}

      <Field name={name("lastName")} isRequired>
        <Label>{translate("__lastName")}</Label>
        <S1InputFormikField type="text" />
      </Field>

      {isDoctor && (
        <>
          <Field name={name("prefix")}>
            <Label>{translate("__prefix")}</Label>
            <S1InputFormikField type="text" />
          </Field>
          <Field name={name("suffix")}>
            <Label>{translate("__suffix")}</Label>
            <S1InputFormikField type="text" />
          </Field>

          <Field name={name("licenseNumber")}>
            <Label>{translate("__licenseNumber")}</Label>
            <S1InputFormikField type="text" />
          </Field>
        </>
      )}

      <Field name={name("email")} isRequired={!isPatient}>
        <Label subLabel={translate("__youMustProvideEmailOrEmployeeId")}>{translate("__email")}</Label>
        <S1InputFormikField type="email" />
      </Field>

      {isPatient && (
        <Field name={name("userName")}>
          <Label subLabel={translate("__youMustProvideEmailOrEmployeeId")}>{translate("__employeeId")}</Label>
          <S1InputFormikField type="text" />
        </Field>
      )}

      {isPatient && (
        <Field name={name("dateOfBirth")} isRequired>
          <Label>{translate("__dob")}</Label>
          <S1InputFormikField type="date" />
        </Field>
      )}

      <Field name={name("phoneNumber")} isRequired={isPatient}>
        <Label>{translate("__phone")}</Label>
        <S1InputFormikField type="text" />
      </Field>

      {isPatient && (
        <Field name={name("accessLevel")} isRequired={isPatient}>
          <Label>{translate("__employeeAccessLevel")}</Label>
          <ReSelectFormikField
            options={EnumHelper.toTranslatedOptions({
              enumObject: AccessLevel,
              translate,
              translationPrefix: "__accessLevel_",
            })}
          />
        </Field>
      )}

      {isDoctor && (
        <Field name={name("cronofySubscriptionId")}>
          <Label>Cronofy Subscription Id</Label>
          <S1InputFormikField
            type="text"
            className="form-control-plaintext"
            readOnly
            placeholder={translate("__calendarNotConfigured")}
          />
        </Field>
      )}
    </>
  );
}

function useData() {
  const userId = getQueryStringValue("userId");
  const newUser = !userId;
  const clientId = getQueryStringValue("clientId");

  const { translate } = useTranslation();
  const toast = useToast();
  const [user, setUser] = useState<MdOrthoUser>();
  const { configuration } = useGetConfiguration({ runOnMount: true });
  const { goBack } = useAppRoutes();
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);

  useRunOnMount(() => {
    async function load() {
      if (!clientId) {
        toast.error(translate("__urlIsInvalidMakeSureYouCopiedWhole"));
        return;
      }

      if (userId) {
        const { data } = await searchUsers({
          request: userFilters.searchUser(userId),
        });
        if (!!data.results?.length) {
          setUser(data.results[0]);
        }
      } else {
        setUser({
          email: "",
          id: "00000000-0000-0000-0000-000000000000",
          userName: undefined,
          firstName: undefined,
          active: true,
          roles: [],
          tenantId: clientId,
          cronofySubscriptionId: undefined,
          accessLevel: AccessLevel.Full,
          patientNotified: false,
          associatedUsers: [],
          data: {},
          createdOn: new Date(),
        });
      }
    }

    load();
  });

  async function save(userInFrom: MdOrthoUser) {
    try {
      toast.clear();
      setSaving(true);
      if (newUser) {
        await createUser({ mdOrthoUser: { ...userInFrom } });
      } else {
        await updateUser({
          mdOrthoUser: {
            ...userInFrom,
          },
        });
      }
      setSuccess(true);
      goBack();
    } catch (e) {
      toast.handleError(e);
    } finally {
      setSaving(false);
    }
  }

  return { userId, user, configuration, save, newUser, success, saving };
}

type UseDataReturnType = ReturnType<typeof useData>;
