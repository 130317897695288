import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faMessage } from "@fortawesome/free-solid-svg-icons/faMessage";
import { faBook } from "@fortawesome/free-solid-svg-icons/faBook";
import { faVideo } from "@fortawesome/free-solid-svg-icons/faVideo";
import { faTasks } from "@fortawesome/free-solid-svg-icons/faTasks";
import { faUserInjured } from "@fortawesome/free-solid-svg-icons/faUserInjured";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle";

import { CtaButton } from "components/CtaButton";
import { useAppRoutes } from "routes/useRoutes";
import { routes } from "routes/routes.config";
import { useAppUser } from "common/useAppUser";
import { Section } from "./components/Section";
import { Card } from "components/Card";
import { useListPatientEncounters } from "api/client/patientEncounter/listPatientEncounters";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { Loading } from "@sprint1/pkg/src/loading";
import { MeetingInfo } from "routes/cg/common/MeetingInfo";
import { ComparisonType } from "@sprint1/pkg/src/api/common/types/comparisonType";

import { addMinutes } from "@sprint1/pkg/src/date/add";
import { getIncompleteInjuries } from "api/client/injury/getIncompleteInjuries";
import { s1Date } from "@sprint1/pkg/src/date";
import { PatientInjury } from "api/types/patientInjury";
import { useState, Fragment } from "react";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faBookMedical } from "@fortawesome/free-solid-svg-icons/faBookMedical";
import { FilterAndSortRequest } from "@sprint1/pkg/src/api/common/types/filterAndSortRequest";
import { AndOrOperator } from "@sprint1/pkg/src/api/common/types/andOrOperator";
import { RolesEnum } from "common/roles";

import { LinkButton } from "@sprint1/pkg/src/button/LinkButton";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { SavingsReportModal, useSavingsReportModal } from "routes/admin/clients/SavingsReportModal";
import { BigLink } from "./Dashboard.BigLink";

export function Dashboard() {
  const { translate } = useTranslation();
  const { patientRoutes, go } = useAppRoutes();
  const appUser = useAppUser();
  const data = useData();
  const scheduleLink = routes.patient.medicalEmergency;
  const savingsReportModal = useSavingsReportModal();

  const showContinueOrScheduleAction =
    appUser.isCurrentRolePatient && (data.incompleteInjury || appUser.hasFullBenefit);
  return (
    <>
      <SwitchRoleButtons />
      <Card>
        <div className="row g-4">
          <div className={`${showContinueOrScheduleAction ? "col-md-8" : "col-md-12"}`}>
            <div className="d-flex justify-content-center flex-wrap">
              {showContinueOrScheduleAction && (
                <BigLink as={Link} icon={faCalendar} text={translate("__scheduleAppointment")} to={scheduleLink} />
              )}

              {appUser.isCurrentRoleClientAdmin && appUser.user?.tenantId && (
                <BigLink
                  as={Link}
                  icon={faUserCircle}
                  text={translate("__manageUsers")}
                  to={routes.admin.users.list.get(appUser.user.tenantId, "").fullUrl}
                />
              )}

              {appUser.isCurrentRoleClientAdmin && appUser.user?.tenantId && (
                <>
                  <BigLink
                    as="button"
                    icon={faDownload}
                    text={translate("__downloadReports")}
                    onClick={() => {
                      savingsReportModal.showModal();
                    }}
                  />
                  <SavingsReportModal {...savingsReportModal} />
                </>
              )}

              {appUser.isCurrentRoleOnSiteNurse && (
                <BigLink
                  as={Link}
                  icon={faUserInjured}
                  text={translate("__workersComp")}
                  to={patientRoutes.selectBodyPart.path}
                />
              )}
              <BigLink as={Link} icon={faMessage} text={translate("__messageCareTeam")} to={routes.messages.pathname} />
              {appUser.isCurrentRolePatient && (
                <BigLink
                  as={Link}
                  icon={faTasks}
                  text={translate("__visitSummaries")}
                  to={routes.patient.visitSummary.path}
                />
              )}

              <BigLink
                as={Link}
                icon={faVideo}
                text={translate("__physicalTherapyVideos")}
                to={routes.library.physicalTherapy}
              />
              <BigLink as={Link} icon={faBook} text={translate("__orthoLibrary")} to={routes.library.ortho} />
              <BigLink
                as="a"
                icon={faBookMedical}
                text={translate("__healthyLivingGuide")}
                href={`/public/docs/HealthyLivingGuide.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
          </div>
          {showContinueOrScheduleAction && (
            <div className={`col-md-4 d-flex flex-column  justify-content-center align-items-center `}>
              <CtaButton
                text={translate("__haveAnInjuryGetStarted")}
                onClick={() => {
                  go(scheduleLink);
                }}
              />
              {data.incompleteInjury && (
                <Link to={routes.patient.questionnaire.url(data.incompleteInjury.id)} className="mt-2">
                  <span>{translate("__continueFillingOutInjury")}</span>

                  <FontAwesomeIcon className="ms-2" icon={faChevronRight} />
                </Link>
              )}
            </div>
          )}
        </div>
      </Card>
      <Appointments />
    </>
  );
}

function SwitchRoleButtons() {
  const appUser = useAppUser();
  const { translate } = useTranslation();
  if (!appUser.user || (appUser.user.roles?.length ?? 0) <= 1) {
    return null;
  }

  if (!shouldShowRoleToggle()) {
    return null;
  }

  return (
    <div className="d-flex justify-content-center py-4">
      <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
        {appUser.isPatient && (
          <Fragment key={`role-${RolesEnum.Patient}}`}>
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id={`switch-role-${RolesEnum.Patient}`}
              autoComplete="off"
              checked={appUser.currentRole === RolesEnum.Patient}
              onChange={() => {
                appUser.setCurrentRole(RolesEnum.Patient);
              }}
            />
            <label className="btn btn-outline-primary" htmlFor={`switch-role-${RolesEnum.Patient}`}>
              {getText(RolesEnum.Patient)}
            </label>
          </Fragment>
        )}

        {appUser.isOnSiteNurse && (
          <Fragment key={`role-${RolesEnum.OnsiteNurse}}`}>
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id={`switch-role-${RolesEnum.OnsiteNurse}`}
              autoComplete="off"
              checked={appUser.currentRole === RolesEnum.OnsiteNurse}
              onChange={() => {
                appUser.setCurrentRole(RolesEnum.OnsiteNurse);
              }}
            />
            <label className="btn btn-outline-primary" htmlFor={`switch-role-${RolesEnum.OnsiteNurse}`}>
              {getText(RolesEnum.OnsiteNurse)}
            </label>
          </Fragment>
        )}

        {appUser.isClientAdmin && (
          <Fragment key={`role-${RolesEnum.ClientAdmin}}`}>
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id={`switch-role-${RolesEnum.ClientAdmin}`}
              autoComplete="off"
              checked={appUser.currentRole === RolesEnum.ClientAdmin}
              onChange={() => {
                appUser.setCurrentRole(RolesEnum.ClientAdmin);
              }}
            />
            <label className="btn btn-outline-primary" htmlFor={`switch-role-${RolesEnum.ClientAdmin}`}>
              {getText(RolesEnum.ClientAdmin)}
            </label>
          </Fragment>
        )}
      </div>
    </div>
  );

  function getText(role: string) {
    if (role === RolesEnum.Patient) {
      return translate("__scheduleAppointmentForMyself");
    }
    if (role === RolesEnum.OnsiteNurse) {
      return translate("__scheduleWorkersCompAppointment");
    }
    if (role === RolesEnum.ClientAdmin) {
      return translate("__manageUsers");
    }
    return translate(`__role-${role}`);
  }

  function shouldShowRoleToggle() {
    let count = 0;
    if (appUser.isPatient) {
      count++;
    }
    if (appUser.isOnSiteNurse) {
      count++;
    }
    if (appUser.isClientAdmin) {
      count++;
    }

    return count > 1;
  }
}

const name = nameof<MdOrthoPatientEncounter>;

function Appointments() {
  const { translate } = useTranslation();
  const { isPatient } = useAppUser();
  const api = useListPatientEncounters();
  useRunOnMount(() => {
    async function load() {
      api.listPatientEncounters({
        request: createFiltersForAppointments(),
      });
    }
    load();
  });

  return (
    <Card>
      <Section titleKey={"__appointments"}>
        <div>
          {(api.isRunning || api.isError) && <Loading />}
          {api.patientEncounters?.results?.length === 0 && <div>{translate("__youDon'tHaveAnyAppointment")}</div>}
          {!!api.patientEncounters?.results.length &&
            api.patientEncounters.results.map((e, index) => {
              return (
                <div key={`appointment-${index}-${e.id}`}>
                  <MeetingInfo
                    patientEncounter={e}
                    displayPatientName={!isPatient}
                    onMeetingCancelled={() => {
                      api.listPatientEncounters({
                        request: createFiltersForAppointments(),
                      });
                    }}
                  />
                </div>
              );
            })}
        </div>
      </Section>
    </Card>
  );
}

function createFiltersForAppointments() {
  const fasRequest: FilterAndSortRequest = {
    filterFields: [],
    sortFields: [{ field: name("startTime"), ascending: false }],
  };

  fasRequest.filterFields.push({
    field: name("cancelled"),
    value: "false",
    comparisonType: ComparisonType.Equals,
    andOrOperator: AndOrOperator.And,
  });
  fasRequest.filterFields.push({
    field: name("closed"),
    value: "false",
    comparisonType: ComparisonType.Equals,
    andOrOperator: AndOrOperator.And,
  });
  const oneHrAgo = addMinutes(-60);
  fasRequest.filterFields.push({
    field: name("startTime"),
    value: oneHrAgo.toISOString(),
    comparisonType: ComparisonType.GreaterThanOrEqual,
    andOrOperator: AndOrOperator.And,
  });

  return fasRequest;
}

function useData() {
  const [incompleteInjury, setIncompleteInjury] = useState<PatientInjury>();
  useRunOnMount(() => {
    async function load() {
      const { data: incompleteInjuries } = await getIncompleteInjuries();
      //Recent to older
      const sorted = incompleteInjuries.sort(
        (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
      );
      if (sorted?.length > 0) {
        const _7daysAgo = s1Date.addDays(-7);
        if (s1Date.is(sorted[0].createdOn).after(_7daysAgo)) {
          setIncompleteInjury(sorted[0]);
        }
      }
    }
    load();
  });

  return { incompleteInjury };
}
