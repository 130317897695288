import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface CommonProps {
  text: string;
  icon: IconProp;
  className?: string;
  style?: React.CSSProperties;
}

export type BigLinkProps<T extends React.ElementType> = CommonProps & {
  as: T;
};

export function BigLink<T extends React.ElementType>({
  as,
  text,
  icon,
  className,
  style,
  ...rest
}: BigLinkProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof CommonProps>) {
  const Component = as as React.ElementType;

  return (
    <Component
      className={`btn btn-outline-primary m-1 m-md-2 p-md-2 ${className ?? ""}`}
      style={{ width: "8rem", ...style }}
      {...rest}
    >
      <div>
        <FontAwesomeIcon icon={icon} size="2x" />
      </div>
      <div className="pt-2">{text}</div>
    </Component>
  );
}
